import { generateClasses } from '@formkit/themes'
import { defineFormKitConfig } from '@formkit/vue'
import {
  createAutoHeightTextareaPlugin,
  createLocalStoragePlugin,
  createMultiStepPlugin,
} from '@formkit/addons'
import { de, fr, it, en } from '@formkit/i18n'
import '~/assets/css/multistep.css'

const classes = generateClasses({
  global: {
    outer: 'field',
    input: 'input',
    label: 'label',
    messages: 'help is-danger',
    message: 'is-danger',
    help: 'help',
  },
  checkbox: {
    outer: '$reset field is-checkbox',
    label: '$reset',
    wrapper: '$reset control',
    inner: '$reset checkbox',
    input: '$reset checkbox',
    legend: '$reset label',
  },
  select: {
    inner: '$reset select',
    input: '$reset',
  },
  radio: {
    label: 'radio',
    wrapper: 'control',
    inner: 'radio',
    input: 'radio',
    legend: 'label',
  },
  textarea: {
    input: '$reset textarea',
  },
  submit: {
    outer: '$reset field',
    input: '$reset button',
  },
  text: {
    wrapper: '$reset field_wrapper',
    outer: '$reset field',
    inner: '$reset control',
    label: '$reset label',
  },
  password: {
    wrapper: '$reset field_wrapper',
    outer: '$reset field',
    inner: '$reset control',
    label: '$reset label',
  },
  number: {
    outer: '$reset',
    wrapper: '$reset field',
    label: '$reset label',
    inner: '$reset control',
  },
})

function addAsteriskPlugin(node: any) {
  if (
    ['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(
      node.props.type,
    )
  )
    return
  const legendOrLabel = ['checkbox', 'radio'].includes(node.props.type)
    ? 'legend'
    : 'label'
  const schemaFn = node.props.definition.schema
  if (node.props.definition.schemaMemoKey) {
    node.props.definition.schemaMemoKey += 'add_astrisks'
  }
  node.props.definition.schema = (sectionsSchema: any = {}) => {
    sectionsSchema[legendOrLabel] = {
      children: [
        '$label',
        {
          $el: 'span',
          if: '$state.required',
          attrs: {
            class: '$classes.asterisk',
          },
          children: ['*'],
        },
      ],
    }

    return schemaFn(sectionsSchema)
  }
}

export default defineFormKitConfig(() => {
  return {
    plugins: [
      addAsteriskPlugin,
      createMultiStepPlugin(),
      createLocalStoragePlugin({
        // plugin defaults:
        prefix: 'formkit',
        key: undefined,
        control: undefined,
        maxAge: 3600000, // 1 hour
        debounce: 200,
        beforeSave: undefined,
        beforeLoad: undefined,
      }),
      createAutoHeightTextareaPlugin(),
    ],
    // plugins: [],
    config: {
      classes,
    },
    locales: { de, fr, it, en },
    locale: 'de',
    messages: {
      de: {
        ui: {
          noFiles: 'Keine Datei ausgewählt',
          incomplete: 'Leider sind nicht alle Felder korrekt ausgefüllt.',
          invalid: 'Leider sind nicht alle Felder korrekt ausgefüllt.',
        },
        validation: {
          email:
            'Die E-Mail Adresse ist ungültig. Bitte überprüfe deine Eingabe.',
        },
      },
      fr: {
        ui: {
          noFiles: 'Aucun fichier choisi',
          incomplete:
            'Désolé, tous les champs ne sont pas remplis correctement.',
          invalid: 'Désolé, tous les champs ne sont pas remplis correctement.',
        },
        validation: {
          email:
            "L'adresse e-mail saisie n'est pas valide. Veuillez la vérifier",
        },
      },
      it: {
        ui: {
          noFiles: 'Nessun file scelto',
          incomplete:
            'Siamo spiacenti, non tutti i campi sono stati compilati correttamente.',
          invalid:
            'Siamo spiacenti, non tutti i campi sono stati compilati correttamente.',
        },
        validation: {
          email:
            "L'indirizzo e-mail inserito non è valido. Si prega di controllare.",
        },
      },
    },
  }
})
